<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de procedimientos"
          icon="fas fa-tablets"
          btn_name="Procedimientos"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'6%'"
          :buttonEdit="true"
          :buttonDelete="true"
          :myButtons="myButtons"
          @mtd_prices="mtd_prices"
          @mtd_supplier="mtd_supplier"
        />
      </CCol>
    </CRow>
    <!-- modal -->
    <CModalForm
      :size="'lg'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow>
        <CCol sm="6">
          <CInput
            label="Nombre"
            placeholder="Digite nombre"
            v-model="service.name"
          />
        </CCol>
        <CCol sm="6">
          <SelectDev
            label="Para"
            :options="options"
            placeholder="Para.."
            :value.sync="service.gender"
          >
          </SelectDev>
        </CCol>
        <CCol sm="6">
          <CTextarea
            label="Descripción"
            v-model="service.description"
            placeholder="Descripción"
          ></CTextarea>
        </CCol>
        <CCol sm="3">
          <CInput
            label="Duración aprox."
            type="time"
            v-model="service.time"
          />
        </CCol>
        <CCol sm="3" v-if="modal.action != 'store'">
          <SelectDev
            label="Estado"
            :options="status"
            placeholder="Estado"
            :value.sync="service.status"
          >
          </SelectDev>
        </CCol>
        <CCol sm="8">
          <CInput
            label="Beneficios"
            type="text"
            placeholder="Beneficio"
            v-model="beneficio"
          >
            <template #append>
              <CButton type="button" color="primary"  @click="mtd_add_benefit">Agregar</CButton>
            </template>
          </CInput>
        </CCol>
        <CCol sm="12" v-if="service.benefit.length>0">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Beneficio</th>
                <th>Borrar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in service.benefit" :key="index">
                <td width="1%">{{index + 1}}</td>
                <td>{{item.name}}</td>
                <td width="1%" class="text-center">
                  <CButton type="button" @click="mtd_del_benefit(index)"  class="btn-sm" color="danger"><i class="fas fa-trash"></i></CButton>
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      title="Borrar Servicio"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>

    <!-- modal precios-->
    <CModalForm
      :size="'md'"
      :title="modal_price.title"
      :button="false"
      :show.sync="modal_price.modal_form"
      @mtd_action="mtd_action_price"
    >
      <CRow>
        <CCol sm="12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>familia/conocidos</th>
                <th>Normal</th>
                <th>Domicilio</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in prices" :key="index">
                <td width="30%" class="text-center">
                  <input type="text" placeholder="0.00" class="form-control form-control-sm" v-model="item.family">
                </td>
                <td class="text-center">
                  <input type="text" placeholder="0.00" class="form-control form-control-sm" v-model="item.normal">
                </td>
                <td width="30%" class="text-center">
                  <input type="text" placeholder="0.00" class="form-control form-control-sm" v-model="item.home">
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal insumos -->
    <CModalForm
      :size="'md'"
      :title="modal_supplier.title"
      :button="false"
      :show_boton="false"
      :show.sync="modal_supplier.modal_form"
      @mtd_action="mtd_action_price"
    >
      <CRow>
        <CCol sm="12" lg="10" xl="10" md="10" >
          <v-select
            :options="suppliers"
            placeholder="Seleccione insumo"
            v-model="supplierAdd"
          />
        </CCol>
        <CCol sm="12" lg="2" xl="2" md="2" >
          <CButton
            color="info"
            @click="mtdAddSupplier"
          >
          <i class="fas fa-plus"></i>
          </CButton>
        </CCol>
        <CCol sm="12" class="mt-2" v-if="lstSupplier.length > 0">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Insumo</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in lstSupplier" :key="index">
                <td width="5%" class="text-center">
                  {{index +1}}
                </td>
                <td >
                  {{item.supplier.name}}
                </td>
                <td width="5%">
                  <CButton
                    color="danger"
                    @click="mtdDelSupplier(index,item.id)"
                  >
                  <i class="fas fa-trash"></i>
                  </CButton>
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
    </CModalForm>
  </div>
</template>

<script>
const fields = [
  { key: "Id", label: "Id", _style: "width:3%" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "for", label: "Para", _style: "width:20%;" },
  { key: "status", label: "Estado", _style: "width:5%;" },
];
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import SelectDev from "../../components/shared/inputs/cSelect.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "v-mantenance-service",
  components: { CTableWrapper, CModalForm, cModalDelete, SelectDev, vSelect },
  data() {
    return {
      prefix: "service",
      fields,
      data: [],
      status: [
        { label: "Activo", value: 1 },
        { label: "Inactivo", value: 0 },
      ],
      options: [
        { label: "ellos", value: 1 },
        { label: "ellas", value: 2 },
        { label: "Unisex", value: 3 },
      ],
      beneficio:"",
      service: {
        id: "",
        name: "",
        gender: [],
        status: [],
        description:"",
        benefit:[],
        time:""
      },
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      myButtons:[
        {
          class: "btn-sm ml-1",
          color: "success",
          tooltip: "Agregar precios",
          action: "mtd_prices",
          icon: "fas fa-dollar-sign",
        },{
          class: "btn-sm ml-1",
          color: "info",
          tooltip: "Agregar insumos",
          action: "mtd_supplier",
          icon: "fas fa-plus",
        },
      ],
      /** prices */
      prices:[],
      modal_price: {
        action: "",
        title: "",
        modal_form: false,
      },
      /** INSUMOS */
      idService:null,
      suppliers:[],
      supplierAdd:[],
      lstSupplier:[],
      modal_supplier: {
        action: "",
        title: "",
        modal_form: false,
      },
    };
  },
  computed: {
    cp_button() {
      if (this.service.name.length > 0 && this.service.gender != "" && this.service.description.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data;
          this.suppliers=response.supplier;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /** */
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Procedimiento")
        : (this.modal.title = "Editar Procedimiento");
      action == "store"
        ? (this.service = {
            id: "",
            name: "",
            gender: [],
            status: [],
            description:"",
            benefit:[],
            time:""
          })
        : (this.service = this.service);
    },
    mtd_add_benefit: function(){
      this.service.benefit.push({
        name: this.beneficio
      });
      this.beneficio = "";
    },
    mtd_del_benefit: function(pos){
      let temp = [];
      this.service.benefit.forEach((element,index) => {
        if (index != pos) {
          temp.push(element);
        }
      });
      this.service.benefit = temp;
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.service,
      })
        .then((response) => {
          if (response.state == 0) {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              message = "REGISTRADO CORRECTAMENTE";
              this.mtd_getdata();
            } else {
              this.mtd_getdata();
              message = "EDITADO CORRECTAMENTE";
            }
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              name: "",
            };
            bus.$emit("alert", {
              color: color,
              message: message,
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.service.id = response.data[0].Id;
          this.service.name = response.data[0].name;
          this.service.gender = parseInt(response.data[0].gender);
          this.service.description = (response.data[0].description);
          this.service.benefit = (response.data[0].benefit);
          this.service.status = response.data[0].status == "Activo" ? 1 : 0;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.Id != response.data.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
    /** precios */
    mtd_prices: function(item){
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix+"/price/"+item.Id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.prices=[];
          this.prices.push(response);
          this.modal_price.modal_form = true;
          this.modal_price.action = "";
          this.modal_price.title = "EDITAR PRECIOS";
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_action_price: function(){
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix+"/price/update",
        token: this.$store.getters.get__token,
        params: this.prices[0],
      })
        .then((response) => {
          if (response.state == 0) {
            let color = "success";
            let message = "EDITADO CORRECTAMENTE";
            this.modal_price= {
              action: "",
              title: "",
              modal_form: false,
            };
            bus.$emit("alert", {
              color: color,
              message: message,
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** insumos */
    mtd_supplier: function(item){
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix+"/supplier/"+item.Id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.lstSupplier=response;
          this.modal_supplier.modal_form = true;
          this.modal_supplier.action = "";
          this.modal_supplier.title = "INSUMOS";
          this.idService=item.Id;
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtdAddSupplier: function(){
      if (this.supplierAdd.length == undefined) {
        let idAdd=this.supplierAdd.value;
        let nameAdd = this.supplierAdd.label;
        let boo = false;
        this.lstSupplier.forEach(element => {
          if(element.supplier_id == idAdd){
            boo = true;
          }
        });
        if (!boo) {
          this.post({
            url: this.$store.getters.get__url + "/" + this.prefix+"/supplier/add",
            token: this.$store.getters.get__token,
            params:{
              supplier: idAdd,
              service:  this.idService
            }
          })
          .then((response) => {
            if (response.state == 0) {
              this.lstSupplier.push(response.data)
              bus.$emit("alert", {
                color: "success",
                message: "Agregado correctamente",
              });
            } else {
              bus.$emit("alert", {
                color: "danger",
                message: response.message,
              });
            }
          })
          .catch((errors) => {
            bus.$emit("alert", {
              color: "danger",
              message: errors,
            });
          });
        }else{
          bus.$emit("alert", {
              color: "warning",
              message: "Insumo ya agregado!",
            });
        }
      }
    },
    mtdDelSupplier: function(pos,id){
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix+"/supplier/delete/"+id,
        token: this.$store.getters.get__token,
      })
      .then((response) => {
        if (response.state == 0) {
          let temp = [];
          this.lstSupplier.forEach((element,index) => {
            if (index != pos) {
              temp.push(element);
            }
          });
          this.lstSupplier = temp;
          bus.$emit("alert", {
            color: "success",
            message: "Eliminado correctamente",
          });
        } else {
          bus.$emit("alert", {
            color: "danger",
            message: response.message,
          });
        }
      })
      .catch((errors) => {
        bus.$emit("alert", {
          color: "danger",
          message: errors,
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>